/* Endpoint: / */
<template>
  <div class="container home-page">
    <div class="content-wrapper">
      <div class="text-content">
        <h1 class="header">
          <span>Zachowaj pamięć</span>
          <br>
          <span>o swoich najbliższych</span>
        </h1>

        <div>
          <p class="paragraph">Stwórz profil pełen pięknych wspomnień i chwil.<br>Niech przyszłe pokolenia zobaczą jak żyli.</p>

          <p class="paragraph get-profile-button-wrapper">
            <a class="get-profile-button" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe8ccQoQ-whUi-izldFe0_z-0Qx5ECXz05AOxn4kpsAd4JaKA/viewform">Zamów Profil Pamięci</a>

            <span>
              <span>lub&nbsp;</span>
              <a class="router-link" href="/profil/55-01-27">zobacz przykładowy profil</a>
            </span>
          </p>
        </div>
      </div>

      <div class="video-content">
        <iframe
            ref="videoPlayer"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="video-player"
            height="397"
            referrerpolicy="strict-origin-when-cross-origin"
            src="https://www.youtube.com/embed/Xw4WDc1Q6DY?si=nl7ab9PckZTg5DuK&amp;controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;list=PLhXRjF087HFjxb8QPnOBKEV2_wn5ovxSz"
            title="TakZyli.pl - platforma do tworzenia Profilu Pamięci"
            width="580"
        ></iframe>
      </div>
    </div>

    <div class="content-wrapper profile-search-wrapper">
      <div v-if="showProfileUnavailableTooltip" class="tooltip">
        Profil o podanym numerze nie istnieje
      </div>

      <v-form class="finder-container" @submit.prevent="seeMemProfile">
        <v-text-field
          v-model="slotNumber"
          :rules="slotNumberRules"
          autofocus
          class="profile-number-input-field"
          dense
          maxlength="8"
          placeholder="Wpisz numer profilu pamięci"
          required
        >
        </v-text-field>

        <v-btn
          :disabled="!slotNumber || slotNumber.replace(/-/g, '').length !== 6"
          class="see-profile-button"
          color="black"
          type="submit"
        >
          Zobacz profil
        </v-btn>
      </v-form>
    </div>

    <h2 class="platform-description">
      Nasza platforma tworzy profil pamięci,<br>umożliwiając utrwalenie najpiękniejszych chwil
      z&nbsp;życia osoby, która odeszła.
    </h2>

    <p class="medium-text">Dodatkowo możesz m. in. upamiętnić:</p>

    <div class="category-type-list">
      <div class="category-type-box">
        <v-icon>
          <img alt="" height="40" src="@/assets/images/rings-icon-40x40.svg" width="40">
        </v-icon>
        <span class="category-name">Rodzinę</span>
      </div>

      <div class="category-type-box">
        <v-icon>
          <img alt="" height="40" src="@/assets/images/book-icon-40x40.svg" width="40">
        </v-icon>
        <span class="category-name">Edukacje</span>
      </div>

      <div class="category-type-box">
        <v-icon>
          <img alt="" height="40" src="@/assets/images/trophy-icon-40x40.svg" width="40">
        </v-icon>
        <span class="category-name">Osiagnięcia</span>
      </div>

      <div class="category-type-box">
        <v-icon>
          <img alt="" height="40" src="@/assets/images/bulb-icon-40x40.svg" width="40">
        </v-icon>
        <span class="category-name">Zainteresowania</span>
      </div>
    </div>

    <div class="divider-wrapper">
      Zachowaj najpiekniejsze wspomnienia dla kolejnych pokoleń.
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/interceptors/axios.config";

export default {
  data() {
    return {
      slotNumber: '',
      slotNumberRules: [
        v => v.replace(/-/g, '').length === 6,
        v => /^\d{2}-\d{2}-\d{2}$/.test(v) || 'Nieprawidłowy numer profilu.',
      ],
      showProfileUnavailableTooltip: false
    };
  },
  watch: {
    slotNumber(newValue) {
      // Remove any non-digit characters
      const digits = newValue.replace(/\D/g, '');
      // Format the digits with hyphens
      const formatted = digits.slice(0, 6).replace(/^(\d{2})(\d{2})?(.*)/, (match, p1, p2, p3) => {
        let result = p1;
        if (p2) result += '-' + p2;
        if (p3) result += '-' + p3;
        return result;
      });
      // Update the value only if it has changed
      if (formatted !== newValue) {
        this.slotNumber = formatted;
      }
    }
  },
  mounted() {
    this.onPlay();
  },
  methods: {
    onPlay() {
      if (!this.$refs?.videoPlayer?.play) {
        return;
      }

      this.$refs?.videoPlayer?.play();
    },
    async seeMemProfile() {
      try {
        if (await this.checkProfileExists(this.slotNumber)) {
          this.$router.push(`/profil/${this.slotNumber}`);
        } else {
          this.showProfileUnavailableTooltip = true;
          setTimeout(() => this.showProfileUnavailableTooltip = false, 3000);
        }
      } catch (error) {
        console.error("Error checking profile:", error);
      }
    },
    async checkProfileExists(profileNumber) {
      try {
        const response = await axiosInstance.get(`${process.env.VUE_APP_API_URL}mem-profile/${profileNumber}`);
        return response.status === 200;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return false;
        } else {
          console.error("Error fetching profile:", error);
          throw error;
        }
      }
    },
    goToClientRegistration() {
      this.$router.push('/client-registration-client');
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-search-wrapper {
  background-color: transparent !important;
  display: flex !important;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 44px 16px 0;
  }
}

.container {
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 40px 0;
  }
}

.button-see-profile-wrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: fit-content;
}

.finder-container {
  margin: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-radius: 16px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    height: 110px;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0 44px;
    gap: 12px;
  }
}
.home-page {
  .black-button {
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    margin-top: 20px; /* Adjust as needed */
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  }

  .black-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
  }
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  background-color: #FFFFFF;
  border-radius: 16px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.text-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 16px 16px;

  @media (min-width: 768px) {
    padding: 88px 16px 88px 88px;
    text-align: left;
  }
}

.video-content {
  width: 100%;
  height: 226px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: 0;
    height: 100%;
  }
}

.video-player {
  height: 226px;
  border: none;

  @media (min-width: 768px) {
    height: 397px;
  }
}

.header {
  font-size: 1.5rem;
  line-height: 130%;
  margin: 16px 0;
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 600;

  @media (min-width: 480px) {
    font-size: 2rem;
    margin: 20px 0;
  }

  @media (min-width: 768px) {
    font-size: 3rem;
    margin: 40px 0;
  }
}

.paragraph {
  display: flex;
  font-size: 0.75rem;
  text-align: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    font-size: 1rem;
    text-align: left;
  }
}

.profile-number-input-field {
  width: 100%;
  min-width: 300px !important;
  border-radius: 4px;
  background-color: transparent;
}

.get-profile-button-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  max-width: 100%;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.get-profile-button {
  width: 100%;
  align-self: center;
  text-transform: none;
  background-color: #2A4F3C;
  color: #FFFFFF;
  border-radius: 8px;
  height: 56px !important;
  padding: 18px 16px;
  text-decoration: none;
  font-size: 0.875rem;
  letter-spacing: 1.25px;
  margin: 16px 0;

  @media (min-width: 768px) {
    width: fit-content;
    align-self: start;
  }
}

.see-profile-button {
  width: 100%;
  align-self: center;
  text-transform: none;
  background-color: #2A4F3C !important;
  border-radius: 8px;
  height: 56px !important;
  padding: 18px 16px;

  @media (min-width: 768px) {
    width: fit-content;
    align-self: start;
  }
}

.router-link {
  color: #000000;
  font-weight: 500;
}

.platform-description {
  width: 100%;
  max-width: 903px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 12px 0;
  line-height: 2rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin: 12px 0 24px;
  }
}

.medium-text {
  font-size: 1.25rem;
  font-family: 'Source Serif 4', sans-serif;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.category-name {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Source Serif 4', sans-serif;
}

.category-type-list {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
}

.category-type-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 168px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;

  @media (min-width: 540px) {
    width: 247px;
  }
}

.divider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Source Serif 4', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  min-height: 160px;
  width: 100%;
  max-width: 1296px;
  margin: 32px 0;
  padding: 0 16px;
  background-image: url('~@/assets/images/divider.png');
  border-radius: 12px;
  letter-spacing: 0.01em;

  @media (min-width: 768px) {
    margin: 144px 0 96px;
    font-size: 1.75rem;
  }
}

.divider {
  width: 100%;
  object-fit: contain;
}

.tooltip {
  position: absolute;
  background-color: #ffcc00;
  color: #000000;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000; /* Ensure the tooltip appears above other elements */
}
</style>
